import React from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

export const GameHeader = ({
  background, btnBackground, btnIconColor, titleColor, title 
}) => {
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate('/')
  }

  return (
    <SGameHeader style={{ background }}>
      <SBackButton
        style={{ background: btnBackground }}
        onClick={handleBackClick}
      >
        <SBackIcon
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 16L10 12L14 8"
            stroke={btnIconColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </SBackIcon>
      </SBackButton>
      
      <STitle style={{ color: titleColor }}>{title}</STitle>
    </SGameHeader>
  )
}

const SGameHeader = styled.div`
  width: 100vw;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const STitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
`

const SBackButton = styled.div`
  position: absolute;
  left: 16px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

const SBackIcon = styled.svg`
  width: 24px;
  height: 24px;
`
