import React, {
  useEffect, useState 
} from 'react'

import styled from 'styled-components'
import snakeImg from 'src/images/snake.png'
import rpsImg from 'src/images/r-p-s.png'
import memoryImg from 'src/images/memory.png'
import xoImg from 'src/images/xo.png'
import numbersImg from 'src/images/numbers.png'
import findWordImg from 'src/images/find-word.png'
import wordsTimeImg from 'src/images/words-time.png'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const cards = [
  {
    icon: snakeImg,
    title: 'Змейка',
    path: '/snake'
  },
  {
    icon: rpsImg,
    title: 'Камень, ножницы, бумага',
    path: '/r-p-s'
  },
  {
    icon: memoryImg,
    title: 'Игра на память',
    path: '/memory'
  },
  {
    icon: xoImg,
    title: 'Крестики-нолики',
    path: '/xo'
  },
  {
    icon: numbersImg,
    title: 'Угадай число',
    path: '/numbers'
  },
  {
    icon: wordsTimeImg,
    title: 'Составь слово на время',
    path: '/words-time'
  },
  {
    icon: findWordImg,
    title: 'Угадай слово',
    path: '/guess-the-word'
  },
]

export const HomePage = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleGameClick = (path) => {
    navigate(path)
  }

  return (
    <SHomePage>
      {cards.map((card) => (
        <SCardBox
          key={card.title}
          onClick={() => handleGameClick(card.path)}
        >
          <SCardIcon src={card.icon} />
          <SCardTitle>{card.title}</SCardTitle>
        </SCardBox>
      ))}
    </SHomePage>
  )
}

const SHomePage = styled.div`
    padding: 24px 8px;
    min-height: 100vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 130px);
    gap: 4px;
    background: black;
`

const SCardBox = styled.div`
  width: 100%;
  height: 130px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 2px 20px 0px #0000001A;
  padding: 16px 12px;
`

const SCardIcon = styled.img`
  width: 40px;
  height: 40px;
`

const SCardTitle = styled.div`
  margin-top: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
`
