import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Stolzl';
    src: url('/fonts/stolzl_regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Stolzl';
    src: url('/fonts/stolzl_light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  html, body {
    width: 100%;
    margin: 0px;
    padding: 0px;
    min-height: 100vh;
  }

  html {
    height: 100%;
    box-sizing: border-box;
    scrollbar-color:#4e5057 #1b1c23;
    scrollbar-width: thin!important;

    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #33363c;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    touch-action: manipulation;
  }

  body {
    font-family: 'Stolzl', sans-serif;
    font-size: 14px;
    line-height: 1;
    background: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: inherit;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a, button {
		text-decoration: none;
    color: initial;
    cursor: pointer;
    outline: none;
  }

  button {
    border: none;
  }

  ol, ul {
    list-style: none;
  }
`
