import {
  createAsyncThunk, createSlice
} from '@reduxjs/toolkit'

import { certificatesApi } from '../api'

const initialState = {
  userId: window?.Telegram?.WebApp?.initDataUnsafe?.user?.id,
  userData: {}
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    }
  },
})

export const appActions = appSlice.actions
export const appReducer = appSlice.reducer
