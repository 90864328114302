import React, { useEffect } from 'react'

import styled from 'styled-components'
import { GameHeader } from 'src/features/app/GameHeader'

export const Memory = () => {
  useEffect(() => {
    const iframe = document.getElementById('game')
    
    iframe.src = '/games-html/memory/index.html'
  }, [])

  return (
    <SnakeWrapper>
      <GameHeader
        background="#6563FF"
        btnBackground="#1C1C1C1A"
        btnIconColor="white"
        title="Игра на память"
        titleColor="white"
      />

      <GameFrame 
        id="game"
        title="Memory Game"
        frameBorder="0"
      />
    </SnakeWrapper>
  )
}

const SnakeWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: #E3F2FD;
  display: flex;
  flex-direction: column;
`

const GameFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
` 