import queryString from 'query-string'

import { HOST } from '@/constants'


const defaultHeaders = {
  // 'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
}

const createFormData = (data) => {
  const formData = new FormData()

  Object.keys(data).forEach((key) => {
    if (data[key].blob) {
      formData.append(key, data[key].blob, data[key].fileName)

      return
    }

    formData.append(key, data[key])
  })

  return formData
}

export const request = (method, url, data, options) => {
  const uri = `${options?.host || HOST}${createUrl(method, url, data)}`

  const headers = new Headers({
    ...defaultHeaders,
    ...createContentType(method, options?.useFormData),
    ...createTokenHeader(getToken()),
    ...options?.headers
  })

  const config = new Request(uri, {
    method,
    headers,
    body: createBody(method, data, options?.useFormData)
  })

  return fetch(config)
    .then(response => response.json())
    .then(response => {
      if (response.error) {
        throw response
      }

      return response
    })
}

const createContentType = (method, useFormData) => {
  if (useFormData) {
    return {}
  }

  if (method !== 'GET') {
    return { 'Content-Type': 'application/json' }
  }

  return null
}

const createTokenHeader = (token) => 
  token ? { Authorization: `Bearer ${token}` } : null

const getToken = () => {
  if (typeof document !== 'undefined') {
    return localStorage.getItem('token')
  }
}

const createBody = (method, data, useFormData) => {
  if (useFormData && data) {
    return createFormData(data)
  }

  if (method !== 'GET' && data) {
    return JSON.stringify(data)
  }

  return undefined
}

const createUrl = (method, url, data) => {
  if (method === 'GET' && data) {
    return `${url}?${queryString.stringify(data)}`
  }

  return url
}