import React, { useEffect } from 'react'

import styled from 'styled-components'
import { GameHeader } from 'src/features/app/GameHeader'
export const Snake = () => {
  useEffect(() => {
    const iframe = document.getElementById('snake-game')
    
    iframe.src = '/games-html/snake/index.html'
  }, [])

  return (
    <SnakeWrapper>
      <GameHeader
        background="#E3F2FD"
        btnBackground="#1C1C1C1A"
        btnIconColor="#000"
        title="Змейка"
      />

      <GameFrame 
        id="snake-game"
        title="Snake Game"
        frameBorder="0"
      />
    </SnakeWrapper>
  )
}

const SnakeWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: #E3F2FD;
  display: flex;
  flex-direction: column;
`

const GameFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
` 