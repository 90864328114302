import React, { useEffect } from 'react'

import {
  useLocation, useNavigationType 
} from 'react-router-dom'

export const ScrollToTopWhenLocationChanged = React.memo(({ children }) => {
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    if (navigationType !== 'POP') {
      window.scrollTo(0, 0)
    }
  }, [location.pathname])

  return <>{children}</>
})