import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import {
  Routes, Route
} from 'react-router-dom'
import {
  QueryClient, QueryClientProvider
} from '@tanstack/react-query'

import { ScrollToTopWhenLocationChanged } from './features/app/ScrollToTopWhenLocationChanged'
import { HomePage } from './pages/HomePage'
import { GlobalStyle } from './ui/styled.global'
import { appActions } from './features/app/appSlice'
import { certificatesApi } from './features/api'
import { Snake } from './pages/Snake'
import { RockPaperScissors } from './pages/RockPaperScissors'
import { Memory } from './pages/Memory'
import { TicTac } from './pages/TicTac'
import { Numbers } from './pages/Numbers'
import { WordsOnTime } from './pages/WordsOnTime'
import { GuessTheWord } from './pages/GuessTheWord'

const queryClient = new QueryClient()

function App() {
  useEffect(() => {
    certificatesApi.sendActivity({ data: { isIncoming: true } })

    window.addEventListener('beforeunload', () => {
      certificatesApi.sendActivity({ data: { isIncoming: false } })
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTopWhenLocationChanged>
        <Routes>
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route 
            path="/snake"
            element={<Snake />}
          />
          <Route 
            path="/r-p-s"
            element={<RockPaperScissors />}
          />
          <Route 
            path="/memory"
            element={<Memory />}
          />
          <Route 
            path="/xo"
            element={<TicTac />}
          />
          <Route 
            path="/numbers"
            element={<Numbers />}
          />
          <Route 
            path="/words-time"
            element={<WordsOnTime />}
          />
          <Route 
            path="/guess-the-word"
            element={<GuessTheWord />}
          />
        </Routes>

        <GlobalStyle />
      </ScrollToTopWhenLocationChanged>
    </QueryClientProvider>
  )
}

const WithAuthorization = ({ children }) => {
  const dispatch = useDispatch()
  
  const [authorized, setAuthorized] = React.useState(false)
  
  useEffect(async () => {
    const initData = window.Telegram.WebApp.initData
    
    const response = await certificatesApi.authUser({ initData })
    
    localStorage.setItem('token', response.jwt)
    dispatch(appActions.setUserData(response.user))
    
    setAuthorized(true)
  }, [])
  
  if (!authorized) {
    return null
  }
  
  return (
    children
  )
}

export default () => {
  return (
    <WithAuthorization>
      <App />
    </WithAuthorization>
  )
}
